<template>
  <base-missing-data :icon="$options.IconMissingDataDefault" :message="message" />
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';

import IconMissingDataDefault from '@/components/MissingDataIcons/MissingDataDefault.vue';

export default {
  name: 'ContractorsMissingData',

  components: { BaseMissingData },

  props: {
    isArchive: { type: Boolean, default: false },
  },

  computed: {
    message() {
      return this.isArchive ? this.$t('contractors.missing_message') : this.$t('contractors.archived_missing_message');
    },
  },

  IconMissingDataDefault,
};
</script>
