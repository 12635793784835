<template>
  <div class="contractors">
    <contractors-missing-data v-if="isDataMissing" class="contractors__missing-data" />

    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column  mb-4">
        <mobile-sort class="mt-2" :sort-list="contractorsHeaders" :sort-by="activeHeader" :options.sync="options" />

        <mobile-search-menu
          :key="$route.name"
          v-model="queryModel"
          :placeholder="$t('label.contractor_search')"
          single-line
          full-width
          hide-details
        />
      </div>

      <search-bar
        v-if="!media.isMobile"
        :key="$route.name"
        v-model="queryModel"
        class="mt-8 mb-6"
        :placeholder="$t('label.contractor_search')"
        single-line
        full-width
        hide-details
      />

      <div class="contractors__list">
        <list-loading v-if="isLoading" />
        <template v-else>
          <contractors-mobile-list v-if="media.isMobile" :items="contractors" @open="openDetailed" />
          <base-table
            v-else
            class="data-table"
            hide-default-footer
            :loading="tableLoading"
            :items="contractors"
            :headers="contractorsHeaders"
            :options.sync="options"
            :server-items-length="contractorsLimit"
            @click:row="openDetailed"
          />
        </template>
      </div>

      <base-pagination v-if="pageCount && !isLoading" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// Constants
import { CONTRACTORS, CONTRACTORS_DETAILED } from '@/constants/routes';

// Utils
import { throttle } from '@/utils/delay';
import { camelToSnake } from '@/utils/formatters';

// Services
import organizationsService from '@/services/organizations';

// Components
import MobileSort from '@/components/MobileSort/index.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import SearchBar from '@/components/SearchBar.vue';
import ListLoading from '@/components/ListLoading.vue';
import ContractorsMobileList from '@/components/Contractors/MobileList.vue';
import ContractorsMissingData from '@/components/Contractors/MissingData.vue';
import BasePagination from '@/components/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';

export default {
  name: 'Contractors',

  components: {
    MobileSort,
    MobileSearchMenu,
    SearchBar,
    ListLoading,
    ContractorsMobileList,
    ContractorsMissingData,
    BasePagination,
    BaseTable,
  },

  inject: ['media'],

  data() {
    return {
      query: '',
      page: +this.$route.query.page || 1,
      pageCount: 1,
      contractorsLimit: 10,
      contractorsCount: 0,
      contractors: [],
      isLoading: false,
      tableLoading: false,
      isDataMissing: false,

      options: { sortBy: '', sortDesc: null },
    };
  },

  computed: {
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.contractorsHeaders.find(contractorHeader => contractorHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },

    contractorsHeaders() {
      return [
        {
          sortText: this.$t('sort_by.contractor'),
          text: this.$t('contractor.contractor'),
          value: 'name',
          sortable: true,
          width: '29%',
        },
        {
          sortText: this.$t('sort_by.type'),
          text: this.$t('contractor.type'),
          value: 'contractorType',
          sortable: true,
          width: '17%',
        },
        {
          sortText: this.$t('sort_by.phone'),
          text: this.$t('contractor.phone'),
          value: 'phone',
          sortable: true,
          width: '25%',
        },
        {
          sortText: this.$t('sort_by.email'),
          text: this.$t('contractor.email'),
          value: 'email',
          sortable: true,
          width: '26%',
        },
      ];
    },

    activeHeader() {
      return this.contractorsHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: CONTRACTORS,
          query: { page: value },
        });
      },
    },

    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },

    offset() {
      return (this.page - 1) * this.contractorsLimit;
    },
  },

  watch: {
    // eslint-disable-next-line
    '$route.query.page': function(newVal, oldVal) {
      if (newVal === oldVal) return;

      this.page = +newVal || 1;
      this.getContractors();
    },

    // eslint-disable-next-line
    '$route.name': function(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (this.isDataMissing) {
        this.isDataMissing = false;
      }
      this.getContractors();
    },

    queryModel(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.$options.throttledSearch();
    },

    orderBy() {
      this.getContractors();
    },

    isDataMissing: {
      handler() {
        this.$emit('update:is-data-missing', this.isDataMissing);
      },
      immediate: true,
    },
  },

  async mounted() {
    if (this.$route.query.page === undefined)
      this.$router.replace({
        query: { page: this.pageModel },
      });

    this.isLoading = true;

    try {
      await this.getContractors();

      if (this.contractors.length === 0 && !this.query) {
        this.isDataMissing = true;
      }
    } finally {
      this.isLoading = false;
    }
  },

  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getContractors();
    }, 500);
  },

  methods: {
    async getContractors() {
      this.tableLoading = true;

      try {
        const data = await organizationsService.getContractors({
          limit: this.contractorsLimit,
          offset: this.offset,
          isArchived: false,
          search: this.query,
          orderBy: this.orderBy,
        });
        const { count, results } = data;

        this.pageCount = Math.ceil(count / this.contractorsLimit);

        this.contractorsCount = count;
        this.contractors = results;
      } finally {
        this.tableLoading = false;
      }
    },

    openDetailed(item) {
      this.$router.push({ name: CONTRACTORS_DETAILED, params: { id: item.id } });
    },
  },
};
</script>

<style lang="scss">
.contractors {
  height: 100%;

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }
}
</style>
