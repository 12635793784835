<template>
  <base-mobile-list class="contractors-mobile-list" :items="items">
    <template v-slot:item="{ item }">
      <v-row no-gutters>
        <div>
          <div class="text-body-1 font-weight-medium" @click="open(item)">
            {{ item.name }}
          </div>
          <div class="text-caption text--secondary">
            {{ item.contractorType }}
          </div>
        </div>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="mt-6">
          <view-list-field :label="$t('contractor.phone')">
            {{ item.phone }}
          </view-list-field>
        </v-col>

        <v-col cols="6" class="mt-6">
          <view-list-field :label="$t('contractor.email')">
            {{ item.email }}
          </view-list-field>
        </v-col>
      </v-row>
    </template>
  </base-mobile-list>
</template>

<script>
// Components
import BaseMobileList from '@/components/BaseMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';

export default {
  name: 'ContractorsMobileList',

  components: { BaseMobileList, ViewListField },

  props: {
    items: { type: Array, required: true },
  },

  methods: {
    open(item) {
      this.$emit('open', item);
    },
  },
};
</script>

<style lang="scss">
.contractors-mobile-list {
  &__full-name {
    color: $--primary-color !important;
  }

  &__contractors-type {
    color: $--mobile-list-secondary-font-color !important;
    opacity: 0.5;
  }
}
</style>
